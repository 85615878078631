<template>
  <div>
    <el-card shadow="never">
      <el-row style="margin-bottom: 22px">
        <el-input v-model="queryInfo.lendOrderCode" class="input" placeholder="借出单号" clearable></el-input>
        <el-input v-model="queryInfo.assetSn" class="input" placeholder="资产码" clearable></el-input>
        <el-input v-model="queryInfo.materialCode" class="input" placeholder="备件编码" clearable></el-input>
        <el-date-picker v-model="dateTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="截止日期" style="margin-right: 10px"></el-date-picker>
        <el-button type="primary" @click="backNumOne" :loading="loading">
          <span v-if="!loading">查询</span>
          <span v-if="loading">查询中</span>
        </el-button>
        <el-button type="primary" @click="dialogVisible = true">添加</el-button>
      </el-row>
      <div style="color: orange;padding-bottom:10px;font-size: 12px">提示：高级检验规则作用是设置哪些备件需要拥有"高级检验员"角色的检验人员才能检验，可根据借出单号、资产码或备件编码进行设置。</div>
      <el-table :data="advancedRule" border style="width: 100%" stripe>
        <el-table-column align="center" prop="lendOrderCode" label="借出单号" width="300px"></el-table-column>
        <el-table-column align="center" prop="assetSn" label="资产码" width="300px"></el-table-column>
        <el-table-column align="center" prop="materialCode" label="备件编码" width="300px"></el-table-column>
        <el-table-column align="center" prop="startDate" label="开始日期"></el-table-column>
        <el-table-column align="center" prop="endDate" label="截止日期"></el-table-column>
        <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="180px">
          <template slot-scope="scope">
            <el-button v-if="scope.row.isOpen === false" type="success" size="small" @click="isOpen(scope.row)">启用</el-button>
            <el-button v-if="scope.row.isOpen === true" type="danger" size="small" @click="isOpen(scope.row)">禁止</el-button>
            <el-button @click="deleteRule(scope.row)" type="danger" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-box">
        <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="currentPage"></el-pagination>
      </div>
      <el-dialog title="添加高级检验规则" :visible.sync="dialogVisible" width="800px" :before-close="handleClose">

        <el-form v-model="addRuleAdvancedData" label-width="200px">
          <el-form-item label="借出单号">
            <el-input v-model="addRuleAdvancedData.lendOrderCode" style="width: 350px" clearable></el-input>
          </el-form-item>
          <el-form-item label="资产码">
            <el-input v-model="addRuleAdvancedData.assetSn" style="width: 350px" clearable></el-input>
          </el-form-item>
          <el-form-item label="备件编码">
            <el-input v-model="addRuleAdvancedData.materialCode" style="width: 350px" clearable></el-input>
          </el-form-item>
          <el-form-item label="起始日期">
            <el-date-picker v-model="addDateTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="截止日期" style="margin-right: 5px"></el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="addRule">确定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "AdvancedInspectionRule",
  data() {
    return {
      queryInfo: {
        pageNo: 1,
        lendOrderCode: '',
        assetSn: '',
        materialCode: '',
        startTime: '',
        endTime: ''
      },
      ruleAdvancedData: {
        lendOrderCode: '',
        assetSn: '',
        materialCode: '',
        startTime: '',
        endTime: ''
      },
      addRuleAdvancedData: {
        lendOrderCode: '',
        assetSn: '',
        materialCode: '',
        startTime: '',
        endTime: '',
        createUserId: ''
      },
      advancedRule: [],
      dateTime: [],
      addDateTime: [],
      type: 'lend_order_code',
      text: '请输入借出单号',
      total: 0,
      currentPage: 1,
      pageSize: 20,
      dialogVisible: false,
      loading: false
    }
  },
  methods: {
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.getRuleAdvancedSelectPage();
    },
    async addRule() {
      this.addRuleAdvancedData.startTime = this.addDateTime[0];
      this.addRuleAdvancedData.endTime = this.addDateTime[1];
      const {data: res} = await this.$axios.post('ruleAdvanced/addRuleAdvanced', this.addRuleAdvancedData);
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.getRuleAdvancedSelectPage();
      this.dialogVisible = false;
      return this.$message.success("添加成功");
    },
    async getRuleAdvancedSelectPage() {
      if (this.dateTime == null || this.dateTime.length < 1) {
        this.dateTime = [];
      } else {
        this.queryInfo.startTime = this.dateTime[0];
        this.queryInfo.endTime = this.dateTime[1];
      }
      const {data: res} = await this.$axios.post('ruleAdvanced/getRuleAdvancedSelectPage', this.queryInfo);
      if (res.code !== 0) {
        return this.$message.error('获取失败！');
      }
      this.advancedRule = res.data.records;
      this.queryInfo.startTime = '';
      this.queryInfo.endTime = '';
      this.total = res.data.total;
      this.pageSize = res.data.size;
      this.loading = false;
      return;
    },
    async deleteRule(row) {
      const {data: res} = await this.$axios.post('ruleAdvanced/deleteRuleAdvanced', {id: row.id});
      if (res.code !== 0) {
        return this.$message.error("删除失败");
      }
      this.getRuleAdvancedSelectPage();
      return this.$message.success("删除成功");
    },
    async isOpen(row) {
      const {data: res} = await this.$axios.post('ruleAdvanced/ruleIsOpen', {id: row.id});
      if (res.code !== 0) {
        return this.$message.error("修改失败");
      }
      this.getRuleAdvancedSelectPage();
      return this.$message.success("修改成功");
    },
    handleClose() {
      this.dialogVisible = false;
    },
    backNumOne() {
      this.loading = true;
      this.currentPage = 1;
      this.queryInfo.pageNo = 1;
      this.getRuleAdvancedSelectPage();
    },
    mounted() {
      this.backNumOne();
    }

  }
}
</script>

<style scoped>
.input {
  width: 300px;
  margin-right: 10px;
}
</style>